#Projects {
  .flex-container {
    display: flex;
    width: 1070px;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;

    .project {
      //background: $ligthgreen;
      padding: 18px;
      box-sizing: border-box;
      border-radius: 13px;
      display: block;
      position: relative;
      width: 318px;
      height: 197px;
      color: #fff;
      text-decoration: none;
      margin-left: 18px;
      margin-right: 18px;

      .footer {
        display: none;
        &_2,
        &_3,
        &_4,
        &_6,
        &_7,
        &_5 {
          bottom: 20px;
          position: absolute;
        }
      }

      &:hover {
        .footer {
          &_2,
          &_3,
          &_4,
          &_6,
          &_7,
          &_5 {
            display: block;
          }
        }
      }

      &:nth-child(1),
      &:nth-child(2) {
        width: 502px;
        height: 431px;
        margin-left: 0;
        margin-right: 11px;

        .name,
        .genre {
          display: none;
        }

        .footer {
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          bottom: 49px;
          position: relative;
          width: 502px;
          height: 431px;
          .about {
            margin-left: 18px;
          }
          .button_block {
            margin-right: 66px;
          }
          .button {
            &:nth-child(2) {
              &:not(:last-child) {
                margin-bottom: 9px;
              }
            }
          }
          .already {
            margin-bottom: 20px;
            font-family: "Fira Sans OT", Roboto, Arial, "Helvetica Neue", Helvetica, sans-serif;
            font-weight: bold;
            font-size: 16px;
            text-transform: uppercase;
          }
        }

        &:nth-child(2) {
            .already {
              display: none;
            }
        }

        &:hover {
          &:not(:nth-child(1)),
          &:not(:nth-child(2)) {
            .hover {
              display: none;
            }
            .triangle {
              opacity: 0;
            }

            .softlaunch {
              opacity: 0;
            }
          }
        }
      }

      &:nth-child(2) {
        left: 21px;
      }

      &:nth-child(4),
      &:nth-child(1) {
        margin-bottom: 36px;
      }

      .name {
        font-family: "Fira Sans OT", Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 16px;
      }

      .hover {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 13px;
        width: 318px;
        height: 197px;
        background: url("/img/social/project_over.png") no-repeat;
      }

      .genre {
        font-family: Roboto, Helvetica Neue, Helvetica, Arial;
        font-size: 14px;
        color: #d7d7d7;
        text-transform: capitalize;
      }

      .triangle {
        opacity: 0;
        background: url('/img/social/play.svg') no-repeat;
        position: absolute;
        width: 318px;
        height: 197px;
        top: 85px;
        left: 155px;
      }

      .softlaunch {
        opacity: 0;
        position: absolute;
        font-family: "Fira Sans OT", Roboto, Helvetica Neue, Helvetica, Arial;
        font-weight: bold;
        font-size: 25px;
        text-transform: uppercase;
        text-align: center;
        width: 160px;
        top: 80px;
        left: 75px;
      }

      //&:nth-child(1) {
      //  .genre {
      //    text-transform: uppercase;
      //  }
      //}

      .status {
        font-family: "Fira Sans OT", Roboto, Helvetica Neue, Helvetica, Arial;
        font-size: 11px;
        line-height: 12px;
        position: absolute;
        text-transform: uppercase;
        right: 7px;
        top: 22px;
      }

      .social {
        position: absolute;
        bottom: 15px;

        img {
          display: inline-block;
          padding-right: 11px;
        }
      }

      &:hover {
        .hover {
          display: block;
        }

        .softlaunch {
          opacity: 1;
        }
      }
    }
  }
}

@for $i from 0 through 8 {
  .project_#{$i} {
    background: url('/img/projects/project_#{$i}.jpg') no-repeat;
  }
}
