#Modal.modal {
  ::-webkit-input-placeholder {
    font-size: pxtovw2(15);
  }

  .modal-content {
    margin-left: pxtovw2(-335);
    width: pxtovw2(670);
    height: pxtovw2(610);
    border-radius: pxtovw2(15);
    padding: pxtovw2(35);
    margin-top: pxtovw2(-300);
  }

  .modal-title {
    font-size: pxtovw2(27);
  }

  .modal-prefs {
    font-size: pxtovw2(15);
    margin-top: pxtovw2(12);
  }

  .modal-hide {
    right: pxtovw2(20);
    top: pxtovw2(20);
    font-size: pxtovw2(20);
  }

  .modal-field {
    margin-bottom: pxtovw2(15);

    label {
      font-size: pxtovw2(17);
      padding-bottom: pxtovw2(5);
    }
  }

  input,
  textarea {
    margin-top: pxtovw2(12);
    border-radius: pxtovw2(10);
    padding: pxtovw2(7) pxtovw2(18);
    width: pxtovw2(560);
    height: pxtovw2(20);
    font-size: pxtovw2(15);
  }

  textarea {
    height: pxtovw2(100);
  }

  .modal-footer {
    padding-top: pxtovw2(30);
  }

  button {
    font-size: pxtovw2(16);
    width: pxtovw2(318);
    height: pxtovw2(53);
    line-height: pxtovw2(53);
  }

  .modal-row {
    margin-top: pxtovw2(10);

    input {
      width: pxtovw2(240);
    }

    & > div {
      &:last-child {
        margin-left: pxtovw2(35);
      }
    }
  }
}