#Job {
  padding-top: pxtovw2(76);
  //margin-bottom: pxtovw2(76);
  .content {
    padding:0 pxtovw2(53);
    box-sizing: border-box;
    width: 100%;
    font-size: pxtovw2(32);
    p {
      font-size: pxtovw2(32);
    }
    h2 {
      text-align: center;
    }
    .mailto {
      padding-bottom: pxtovw2(50);
      padding-top: pxtovw2(35);
      .mail-link {
        font-size: pxtovw2(16 * 2);
        width: pxtovw2(318);
        height: pxtovw2(53);
        line-height: pxtovw2(53);
        box-shadow: 0 0 pxtovw2(8) rgba(255, 255, 255, 0.5);
        border-radius: pxtovw2(13);
      }
    }
    & > div {
      padding-bottom: pxtovw2(10);
    }
    ul {
      margin-left: pxtovw2(16 * 2);

      li {
        font-size: pxtovw2(16 * 2);
        line-height: 2.3;
      }
    }
  }
}