#Navbar {
  background: $darkgreen;
  display: flex;
  height: $navbar-height;
  margin-top: 0;
  position: fixed;
  transition: all .1s;
  width: 100%;
  z-index: 100;
  align-items: center;
  .content {
    width: 1026px;
  }
  a {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
    color: $ligthgrey;
    font-family: "Fira Sans OT", Helvetica Neue, Helvetica, Arial;
  }
  .menu {
    a {
      &:hover, :active, &.active {
        color: white;
        border-bottom: 2px solid $pink;
      }
    }
  }
  &.scrolled {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.5)
  }
  .logo {
    top: 16px;
    position: absolute;
    &.false {
      display: none;
    }
    img {
      width: 71px;
    }
  }
  .menu {
    text-align: center;
    width: 750px;
    margin: 0 auto;
    li {
      display: inline-block;
      text-align: center;
      width: 25%;
      &:last-child {
        display: none;
      }
    }
  }
  .burger {
    display: none;
  }
  .lang {
    font-weight: bold;
    cursor: pointer;
    color: #a19997;
    font-family: "Fira Sans OT", Helvetica Neue, Helvetica, Arial;
    float: right;
    text-transform: uppercase;
    position: relative;
  }
}
