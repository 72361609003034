@import "../mixins/pxem";
.ico { background-image: url(~../assets/sprites/sprite.png) }
.ico-button-back { width: pxtovw(100); height: pxtovw(200); background-position: pxtovw(0) pxtovw(-256); background-size: pxtovw(100) pxtovw(200);}
.ico-button-more { width: pxtovw(434); height: pxtovw(256); background-position: pxtovw(0) pxtovw(0); background-size: pxtovw(434) pxtovw(256);}
.ico-button-off { width: pxtovw(100); height: pxtovw(200); background-position: pxtovw(-100) pxtovw(-256); background-size: pxtovw(100) pxtovw(200);}
.ico-external_link { width: pxtovw(28); height: pxtovw(52); background-position: pxtovw(-255) pxtovw(-300); background-size: pxtovw(28) pxtovw(52);}
.ico-internal_link { width: pxtovw(55); height: pxtovw(55); background-position: pxtovw(-200) pxtovw(-300); background-size: pxtovw(55) pxtovw(55);}
.ico-liked { width: pxtovw(40); height: pxtovw(35); background-position: pxtovw(-295) pxtovw(-256); background-size: pxtovw(40) pxtovw(35);}
.ico-new { width: pxtovw(95); height: pxtovw(44); background-position: pxtovw(-200) pxtovw(-256); background-size: pxtovw(95) pxtovw(44);}
.ico-notliked { width: pxtovw(40); height: pxtovw(35); background-position: pxtovw(-335) pxtovw(-256); background-size: pxtovw(40) pxtovw(35);}
.ico-tile { width: pxtovw(256); height: pxtovw(256); background-position: pxtovw(-434) pxtovw(0); background-size: pxtovw(256) pxtovw(256);}