
#Projects {
  .flex-container {
    width: pxtovw(1026);

    .project {
      border-radius: pxtovw(13);
      width: pxtovw(318);
      height: pxtovw(197);
      margin-left: pxtovw(15);
      margin-right: pxtovw(15);

      padding: pxtovw(18);
      &:nth-child(4) {
        margin-bottom: 0;
      }

      &:nth-child(1),
      &:nth-child(3),
      &:nth-child(5),
      &:nth-child(7) {
        margin-bottom: pxtovw(18);
      }

      & > div {
        position: absolute;
      }


      .hover {
        background-size: pxtovw(318) !important;
      }

      .name {
        font-size: pxtovw2(16);
      }

      .hover {
        border-radius: pxtovw(13);
        width: pxtovw(318);
        height: pxtovw(197);
      }

      .genre {
        font-size: pxtovw2(16);
        top: pxtovw2(45);
      }

      .triangle {
        width: pxtovw(318);
        height: pxtovw(197);
        top: pxtovw(80);
        left: pxtovw(150);
      }

      .softlaunch {
        font-size: pxtovw(25);
        width: pxtovw(160);
        top: pxtovw(80);
        left: pxtovw(75);
      }

      .status {
        font-size: pxtovw2(11);
        line-height: pxtovw2(12);
        right: pxtovw2(6);
        top: pxtovw2(21);
      }

      .social {
        bottom: pxtovw(15);

        img {
          padding-right: pxtovw(11);
        }
      }
    }
  }
}

@for $i from 2 through 9 {
  .project_#{$i} {
    background-size: pxtovw(318);
  }
}

.project_0,
.project_1 {
  width: pxtovw(502) !important;
  height: pxtovw(431) !important;
  background-size: pxtovw(502);
  .footer {
    width: pxtovw(502) !important;
    height: pxtovw(431) !important;
  }
}
