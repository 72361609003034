@mixin soc_url($pr) {
  .#{$pr} {
    background: url("/img/social/#{$pr}_normal.svg") no-repeat;

    &:hover {
      background: url("/img/social/#{$pr}_over.svg") no-repeat;
    }
  }
}

#Footer {
  height: 162px;
  background: url("../assets/img/new_footer_piggy.jpg") top center no-repeat #0b2421;

  font-size: 14px;

  &.project {
    //height: 217px;
    background: url("../assets/img/new_footer.jpg") top center no-repeat #0b2421;
  }

  .inner {
    color: #c9c9c9;
    width: 1028px;
    margin: 0 auto;
    padding-top: 40px;
    box-sizing: border-box;
    font-family: "Fira Sans OT", Roboto, Helvetica Neue, Helvetica, Arial;

    a {
      color: #c9c9c9;
      //text-decoration: underline;
      &:hover, :active, &.active {
        color: white;
        border-bottom: 2px solid $pink;
      }
    }

    & > div {
      display: inline-block;
      vertical-align: top;
    }

    .privacy {
      & > span {
        display: block;
        padding-bottom: 18px;


      }

      margin-right: 185px;
    }

    .social {
      color: white;
      .links {
        color: white;
        margin-top: 16px;

        & > div {
          width: 36px;
          height: 36px;
          display: inline-block;
          vertical-align: top;
          margin-right: 18px;
          a {
            color: white;
            width: 36px;
            height: 36px;
            display: block;
            text-decoration: none;
            cursor: pointer;
            &:hover,
            &:hover {
              border-bottom: none;
            }
          }
        }

        @include soc_url(ig);
        @include soc_url(fb);
        @include soc_url(vk);
        @include soc_url(ok);
        @include soc_url(iMr);
        @include soc_url(mm);
        @include soc_url(bw);
        @include soc_url(in);
        @include soc_url(arts);
        @include soc_url(android);
        @include soc_url(apple);
        @include soc_url(dd);
      }
    }

    .support {
      margin-left: 280px;
    }
  }
}
