
#Navbar {
  margin-top: 0;
  width: 100%;
  z-index: 100;

  .content {
    width: pxtovw(980);
  }

  a {
    font-size: pxtovw(16 * 3);
  }

  &.scrolled {
    box-shadow: pxtovw(0) pxtovw(1) pxtovw(10) rgba(0, 0, 0, 0.5)
  }

  .logo {
    top: pxtovw(16 * 3);
    &.false {
      //display: block;
    }
  }

  .menu {
    background: $darkgreen;
    box-shadow: inset 0px 1px 10px 2px rgba(0,0,0,0.3);
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: pxtovw(195);
    padding-top: pxtovw(100);
    opacity: 0;
    display: none;
    li {
      text-align: left;
      display: block;
      width: 100%;
      height: pxtovw(120);
      line-height: pxtovw(120);
      padding-left: pxtovw(80);
      &:last-child {
        display: block;
      }
      a {
        font-size: pxtovw(60);
      }
    }
  }

  .lang {
    display: none;
  }

  .mobile_lang {
    display: block;
    .lang {
      display: block;
      position: absolute;
      bottom: pxtovw(400);
      color: #fff;
    }
  }

  .burger {
    display: block;
    color: #a19997;
    position: absolute;
    top: pxtovw(70);
    right: pxtovw(40);
    font-size: pxtovw(80);
  }
}