@mixin soc_url($pr) {
  .#{$pr} {
    background: url("/img/social/#{$pr}_normal.svg") no-repeat;

    &:hover {
      background: url("/img/social/#{$pr}_over.svg") no-repeat;
    }
  }
}


#Footer {
  height: pxtovw(162 * 2);
  font-size: pxtovw(14 * 1.5);

  background-size: cover !important;

  &.project {
    //height: pxtovw(217);
  }

  .inner {
    width: pxtovw(1028);
    margin: 0 auto;
    padding-top: pxtovw(40);

    a {
      &:hover, :active, &.active {
        border-bottom: pxtovw(2) solid $pink;
      }
    }

    & > div {
    }

    .privacy {
      & > span {
        padding-bottom: pxtovw(18);
      }

      margin-right: pxtovw(150);
    }

    .social {
      margin-left: pxtovw(15);
      margin-right: pxtovw(15);

      .links {
        margin-top: pxtovw(16 * 2);

        & > div {
          width: pxtovw(36 * 1.5);
          height: pxtovw(36 * 1.5);
          margin-right: pxtovw(18 * 1.5);
          background-size: pxtovw(36 * 1.5) !important;

          a {
            width: pxtovw(36 * 1.5);
            height: pxtovw(36 * 1.5);
          }
        }

        @include soc_url(fb);
        @include soc_url(vk);
        @include soc_url(ok);
        @include soc_url(iMr);
        @include soc_url(mm);
        @include soc_url(bw);
        @include soc_url(in);
        @include soc_url(arts);
        @include soc_url(android);
        @include soc_url(apple);
      }
    }

    .support {
      margin-left: pxtovw(280);
    }
  }
}