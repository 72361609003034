#Job {
    padding-top: 50px;
    //margin-bottom: 76px;
    h2 {
        margin-top: 70px;
        padding-top: 25px;
        text-align: left;
    }

    .reflink {
        margin-top: 18px;
        margin-bottom: 40px;
        .ref-link {
            color: #454545;
            background: #fff;
            display: inline-block;
            text-align: center;
            text-decoration: none;
            font-size: 16px;
            font-weight: bold;
            font-family: "Fira Sans OT", Roboto, Helvetica Neue, Helvetica, Arial;
            width: 318px;
            height: 53px;
            margin: 0 auto;
            line-height: 53px;
            box-shadow: 0 0 8px rgba(255, 255, 255, 0.5);
            border-radius: 13px;
            border: 1px solid #d2d2d2;
            cursor: pointer;
        }
    }

    .content {
        width: 1026px;
        margin: 0 auto;

        .row {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .col {
                &-1 {
                    width: 670px;
                }

                &-2 {
                    margin-top: 70px;
                    width: 320px;
                    text-align: center;
                }
            }
        }

        .mailto {
            width: 100%;
            position: relative;
            text-align: left;
            text-transform: uppercase;
            color: white;
            padding-bottom: 50px;
            padding-top: 35px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;

            .recinfo {
                text-transform: none;
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;

                .rectitle {
                    margin-top: 20px;
                    font-size: 16px;
                    font-weight: bold;
                    font-family: "Fira Sans OT", Helvetica Neue, Helvetica, Arial;
                    color: #333;
                }

                .recsoc {
                    display: flex;
                    margin-top: 20px;
                    margin-bottom: 15px;

                    .share {
                        margin-left: 9px;
                        margin-right: 9px;

                        img {
                            width: 36px;
                        }
                    }
                }

                .reclink {
                    a {
                        font-size: 14px;
                        font-family: "Fira Sans OT", Helvetica Neue, Helvetica, Arial;
                        color: #333;
                        text-decoration: underline;

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }

            .mail-link {
                color: #fff;
                background: #00a89e;
                display: inline-block;
                text-align: center;
                text-decoration: none;
                font-size: 16px;
                font-weight: bold;
                font-family: "Fira Sans OT", Roboto, Helvetica Neue, Helvetica, Arial;
                width: 318px;
                height: 53px;
                margin: 0 auto;
                line-height: 53px;
                box-shadow: 0 0 8px rgba(255, 255, 255, 0.5);
                border-radius: 13px;
                //border: 1px solid #d2d2d2;
                cursor: pointer;

                &:hover {
                    color: #fff;
                    background: #00a89e;
                }
            }
        }

        & > div {
            padding-bottom: 10px;
        }

        ul {
            margin-left: 16px;

            li {
                font-size: 14px;
                font-family: "Roboto", Arial, "Helvetica Neue", Helvetica, sans-serif;
                line-height: 2.3;
                list-style-image: url("./../assets/img/star_bullit_red.png");
            }
        }
    }
}
