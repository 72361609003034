
#Navbar {
  margin-top: 0;
  width: 100%;
  z-index: 100;

  .content {
    width: pxtovw(980);
  }

  a {
    font-size: pxtovw(16 * 1.5);
  }

  .menu {
    a {
      &:hover, :active, &.active {
        border-bottom: pxtovw(2) solid $pink;
      }
    }
  }

  &.scrolled {
    box-shadow: pxtovw(0) pxtovw(1) pxtovw(10) rgba(0, 0, 0, 0.5)
  }

  .logo {
    top: pxtovw(16 * 1.5);
  }

  .menu {
    width: pxtovw(750);
    margin: 0 auto;

    li {
      width: 25%;
      &:last-child {
        display: block;
      }
    }
  }

  .lang {
  }
}