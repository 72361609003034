
#Slider {
  height: pxtovw(900 * 1.5);
  .logo {
    width: pxtovw(485 * 1.5);
    height: pxtovw(317 * 1.5);
    margin-left: - pxtovw(242.5 * 1.5);
    margin-top: 20%;
    background-size: pxtovw(392 * 1.5);
  }

  .planets {
    display: none;
  }

  .inner {
    .slogan {
      margin-top: pxtovw(593 * 1.5);
      font-size: pxtovw(26 * 1.5);
      margin-bottom: pxtovw(70 * 1.5);
    }

    .workus {
      a {
        font-size: pxtovw(16 * 2);
        width: pxtovw(318 * 2);
        height: pxtovw(53 * 2);
        line-height: pxtovw(53 * 2);
        border-radius: pxtovw(13 * 2);
      }
    }
  }
}