@mixin soc_url($pr) {
  &.#{$pr} {
    background: url("/img/social/#{$pr}_normal.svg") no-repeat;

    &:hover {
      background: url("/img/social/#{$pr}_over.svg") no-repeat;
    }
  }
}

#Project {
  background: #0f332f;
  padding-top: $navbar-height;

  .main_image {
    //height: 647px;
  }

  .inner {
    background: #fff;

    .content {
      width: 1100px;
      margin: 0 auto;
    }
  }

  .name {
    font-family: "Fira Sans OT", Helvetica Neue, Helvetica, Arial;
    font-size: 50px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .text {
    line-height: 1.2;
  }

  .available {
    padding-top: 38px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .play_link_ios {
      margin-right: 20px;
    }

    .available_text {
      margin-left: 40px;
      font-family: "Fira Sans OT", Helvetica Neue, Helvetica, Arial;
      font-size: 16px;
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  .not_available {
    padding-top: 38px;
    //display: flex;
    //justify-content: flex-start;
    //align-items: center;

    .available_text {
      margin-bottom: 24px;
      a {
        color: #333;
        border-bottom: none;
        &:hover {
          border-bottom: 1px solid #ff5759;
        }
      }
    }

    .play_link {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .play_buttons {
      display: flex;
      & > div {
        padding: 0 10px;
      }
    }

    .available_text {

    }

    .play_in {
      font-family: "Fira Sans OT", Helvetica Neue, Helvetica, Arial;
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 17px;
    }
  }

  .social {
    display: none;
    height: 181px;
    background: url("../assets/img/play_social_bg.jpg") top center no-repeat #0b2421;
    width: 100%;
    font-family: Fira Sans OT, Roboto, Arial, Helvetica Neue, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 16px;
    color: #fff;
    text-align: center;
    box-sizing: border-box;
    padding-top: 50px;

    &.show {
      display: block;
    }

    .title {
      text-transform: uppercase;
      margin-bottom: 20px;
    }

    .links {
      ul {
        li {
          display: inline-block;
          width: 36px;
          height: 36px;
          margin: 0 8px;
          cursor: pointer;
          @include soc_url(fb);
          @include soc_url(vk);
          @include soc_url(ok);
          @include soc_url(iMr);
          @include soc_url(mm);
          @include soc_url(bw);
          @include soc_url(in);
          @include soc_url(arts);
          @include soc_url(android);
          @include soc_url(apple);

          a {
            display: block;
            width: 35px;
            height: 35px;
            text-decoration: none;
          }
        }
      }
    }

  }

  .description {
    padding: 0 38px;
    //padding-top: 60px;
    margin-bottom: -4px;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    //& > div {
    //  display: inline-block;
    //  vertical-align: top;
    //}

    .image {
      padding-top: 35px;
    }

    .descr {
      //width: 695px;
      padding-left: 32px;
      padding-top: 78px;
      //padding-bottom: 20px;
      box-sizing: border-box;
      font-family: Roboto, Arial, Helvetica Neue, Helvetica, sans-serif;
      font-size: 14px;

      .text {

      }

      .tech {
        ul {
          margin-top: 20px;

          li {
            display: inline-block;
            width: 119px;
            height: 37px;
            margin-right: 17px;

            border: 1px solid #d2d2d2;
            box-sizing: border-box;
            border-radius: 7px;
            text-align: center;

            font-family: "Fira Sans OT", Roboto, Helvetica Neue, Helvetica, Arial;
            font-size: 17px;
            line-height: 37px;
          }
        }
      }
    }
  }

  .features {
    margin: 0 auto;
    margin-top: 70px;
    width: 1024px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;

    .image {
      &_3,
      &_0 {
        margin-bottom: 40px;
      }
    }

    & > div {
      width: 50%;
    }

    ul {
      margin-left: 16px;

      li {
        font-size: 14px;
        font-family: "Roboto", Arial, "Helvetica Neue", Helvetica, sans-serif;
        line-height: 1.3;
        list-style-image: url("./../assets/img/star_bullit_red.png");
        margin-bottom: 17px;
      }
    }
  }

  .next_game {
    position: relative;
    margin-top: 20px;
    margin-bottom: 70px;
    text-align: center;
    a {
      color: #333;
      display: inline-block;
      text-align: center;
      text-decoration: none;
      font-size: 16px;
      font-weight: bold;
      font-family: "Fira Sans OT", Roboto, Helvetica Neue, Helvetica, Arial;
      width: 318px;
      height: 53px;
      margin: 0 auto;
      line-height: 53px;
      box-shadow: 0 0 8px rgba(255, 255, 255, 0.5);
      border-radius: 13px;
      border: 1px solid #d2d2d2;
      cursor: pointer;
      text-transform: uppercase;

      &:hover,
      &:active {
        color: #fff;
        background: #00a89e;
      }
    }
  }

  .video-item {
    width: 100%;
    max-width: 1900px;
    display: block;
    margin: 0 auto
  }
}

video {
  background-color: transparent !important;
}
