
#Company {
  .content {
    width: pxtovw(1026);
  }

  .inner {
    //height: pxtovw(665);

    .body {
      //height: pxtovw(485);
    }

    .bottom {
      width: pxtovw(1030);
      height: pxtovw2(120);
      margin: 0 auto;
      padding-left: pxtovw(85);
      padding-top: 0;

      .achive {
        margin-right: pxtovw(150);
        margin-top: 0;
        height: inherit;

        &:nth-child(2) {
          margin-right: pxtovw(136);
        }

        &:last-child {
        }

        & > span {
        }

        .number {
          font-size: pxtovw(100);
          padding-right: pxtovw(15);
          line-height: pxtovw(80);
        }

        .text {
          font-size: pxtovw(16);
        }
      }
    }
  }

  .front-slider {
    .slides {
      width: pxtovw(1026);
      height: pxtovw(684);

      .container {
        width: pxtovw(10000);

        img {
          width: pxtovw(1030);
        }
      }
    }

    .controls {
      & > div {
        width: pxtovw(36*2);
        height: pxtovw(36*2);
        background-size: pxtovw(72) !important;
        margin-top: pxtovw(280);
        margin-left: pxtovw2(-20);
      }

      .left {
      }

      .right {
        margin-left: pxtovw2(972);
      }
    }

    .bullets {
      margin-top: pxtovw(15*2);

      .bull {
        width: pxtovw2(9*3);
        height: pxtovw2(9*3);
        margin: 0 pxtovw(10);
        background-size: pxtovw2(9*3);
        &.active {
          background-size: pxtovw2(9*3);
        }
      }
    }
  }
}