#Jobs {
  margin-bottom: 70px;

  .inner {
    width: 1026px;
    margin: 0 auto;

    .wanted {
      text-align: center;
      margin-top: 50px;

      a {
        color: #333333;
        font-weight: bold;

        &:hover {
          border-bottom: 1.5px solid #ff5759;
        }
      }
    }

    .navigation {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      margin-bottom: 70px;

      .nav {
        display: block;
        width: 20%;
        text-align: center;
        color: #333;
        text-transform: uppercase;
        font-weight: bold;
        cursor: pointer;
        font-family: Fira Sans OT, Roboto, Arial, Helvetica Neue, Helvetica, sans-serif;

        span {
          width: 100%;
        }

        &:hover,
        &.active {

          span {
            color: #333;
            border-bottom: 2px solid $pink;
          }
        }

        &:first-child {
          text-align: left;
        }

        &:last-child {
          text-align: right;
        }
      }
    }

    .tabs {
      .tab {
        display: none;

        @for $i from 1 through 5 {
          &.tab_#{$i} {
            .img_src {
              background: url("./../assets/img/op_#{$i}.png");
            }
          }
        }

        &.active {
          display: block;
        }

        .description {
          margin-bottom: 10px;

          .img {
            display: table-cell;
            vertical-align: top;

            .img_src {
              //background: $ligthgreen;
              //border-radius: 13px;
              height: 197px;
              width: 319px;
              margin-bottom: 20px;
              float: left;
            }
          }

          .text {
            display: table-cell;
            vertical-align: top;
            padding-left: 37px;

            .top {
              font-weight: bold;
              font-size: 17px;
              padding-bottom: 25px;
            }

            p {
              margin-block-start: 0;
            }

            strong {
              font-weight: normal;
              font-family: Fira Sans OT, Roboto, Arial, Helvetica Neue, Helvetica, sans-serif;
            }

            ul {
              margin-left: 16px;
              margin-top: 20px;

              li {
                font-size: 14px;
                font-family: "Roboto", Arial, "Helvetica Neue", Helvetica, sans-serif;
                //line-height: 2.3;
                padding-bottom: 20px;
                list-style-image: url("./../assets/img/star_bullit_red.png");
              }
            }
          }
        }

        .jobs {
          .job {
            a {
              color: #009e94;
            }

            .mailto {
              width: 100%;
              position: relative;
              text-align: left;
              text-transform: uppercase;
              color: white;
              padding-bottom: 50px;
              padding-top: 35px;

              .mail-link {
                color: #333;
                display: inline-block;
                text-align: center;
                text-decoration: none;
                font-size: 16px;
                font-weight: bold;
                font-family: "Fira Sans OT", Roboto, Helvetica Neue, Helvetica, Arial;
                width: 318px;
                height: 53px;
                margin: 0 auto;
                line-height: 53px;
                box-shadow: 0 0 8px rgba(255, 255, 255, 0.5);
                border-radius: 13px;
                border: 1px solid #d2d2d2;
                cursor: pointer;

                &:hover {
                  color: #fff;
                  background: #00a89e;
                }
              }
            }

            .header {
              border-radius: 13px;
              border: 1px solid #d2d2d2;
              cursor: pointer;
              margin-top: -1px;

              & > div {
                color: #666;
                display: table-cell;
                font-size: 17px;
                padding: 17px;
                width: 200px;
                text-align: center;
                box-sizing: border-box;
              }

              .link,
              .toggle {
                width: 30px;
              }

              .timing,
              .price {
                width: 200px;
                white-space: nowrap;
              }

              .price {
                width: 250px;
              }

              .title {
                color: #333;
                width: 250px;
                text-align: left;
                font-weight: normal;
                font-family: Fira Sans OT, Roboto, Arial, Helvetica Neue, Helvetica, sans-serif;
              }

              .price {

              }

              .toggle {
                width: 50px;
              }
            }

            .content {
              display: none;
              color: #333;
              padding: 0 18px;
              box-sizing: border-box;
              font-size: 16px;

              p {
                padding-top: 4px;
                margin-block-end: 10px;
                font-size: 14px;

                strong {
                  font-family: "Fira Sans OT", Roboto, Helvetica Neue, Helvetica, Arial;
                  font-size: 16px;
                  padding-top: 20px;
                  padding-bottom: 20px;
                  font-weight: normal;
                }
              }

              ul {
                margin-left: 16px;

                li {
                  font-size: 14px;
                  font-family: "Roboto", Arial, "Helvetica Neue", Helvetica, sans-serif;
                  line-height: 2.3;
                  list-style-image: url("./../assets/img/star_bullit_red.png");
                }
              }

              &.active {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}