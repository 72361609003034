
#Workus {
  .flex-container {
    width: pxtovw(1026);

    .work {
      width: pxtovw(318);
      flex-shrink: 3;
      margin-left: pxtovw(15);
      margin-right: pxtovw(15);

      &:nth-child(1) {
        margin-bottom: pxtovw(40);
      }

      &:nth-child(4) {
        margin-bottom: pxtovw(60);
      }

      .img {
        height: pxtovw(197);
        margin-bottom: pxtovw(40);
        background-size: pxtovw(318) !important;
      }

      .title {
        font-size: pxtovw2(20);
        margin-bottom: pxtovw(12);
      }

      .text {
        font-size: pxtovw2(19);
      }
    }
  }
}