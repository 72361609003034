
#Company {
  .content {
    width: pxtovw2(1026);
  }

  .inner {
    //height: pxtovw(665);

    .body {
      //height: pxtovw(485);
    }

    .bottom {
      width: pxtovw2(1026);
      //height: pxtovw2(200);
      margin: 0 auto;
      padding-left: pxtovw2(10);
      //padding-top: pxtovw2(40);

      .achive {
        margin-right: pxtovw2(150);
        //margin-top: pxtovw2(50);
        //height: pxtovw2(100);

        &:nth-child(2) {
          margin-right: pxtovw2(136);
        }

        &:last-child {
        }

        & > span {
        }

        .number {
          font-size: pxtovw2(100);
          padding-right: pxtovw2(15);
          line-height: pxtovw2(80);
        }

        .text {
          font-size: pxtovw2(18);
        }
      }
    }
  }

  .front-slider {
    .slides {
      width: pxtovw2(1026);
      height: pxtovw2(684);

      .container {
        width: pxtovw2(10000);

        img {
          width: pxtovw2(1026);
        }
      }
    }

    .controls {
      & > div {
        width: pxtovw2(18*3);
        height: pxtovw2(18*3);
        margin-top: pxtovw2(325);
        margin-left: pxtovw2(-20);
        background-size: pxtovw2(18*3) !important;
      }

      .left {
      }

      .right {
        margin-left: pxtovw2(990);
      }
    }

    .bullets {
      margin-top: pxtovw2(30);

      .bull {
        width: pxtovw2(16);
        height: pxtovw2(16);
        background-size: pxtovw2(16);
        margin: 0 pxtovw2(20);
        &.active {
          background-size: pxtovw2(16);
        }
      }
    }
  }
}