#Company {
  .content {
    width: 1026px;
    margin: 0 auto;
  }
  .inner {
    //height: 665px;
    //background: $green;
    .body {
      //height: 485px;
    }
    .bottom {
      color: #333333;
      width: 1030px;
      height: 150px;
      margin: 0 auto;
      padding-left: 85px;
      //padding-top: 40px;
      .achive {
        margin-right: 150px;
        //margin-top: 50px;
        //height: 203px;
        display: inline-block;
        vertical-align: top;
        text-align: right;
        //color: white;
        font-family: "Fira Sans OT", Helvetica Neue, Helvetica, Arial;
        &:nth-child(2) {
          margin-right: 136px;
        }
        &:last-child {
          margin-right: 0;
        }
        & > span {
          display: inline-block;
          vertical-align: top;
          text-align: left;
        }
        .number {
          font-size: 100px;
          font-weight: bold;
          padding-right: 15px;
          line-height: 80px;
        }
        .text {
          font-size: 16px;
          text-align: left;
          position: relative;
          font-weight: bold;
          text-transform: uppercase;
        }
      }
    }
  }
  .front-slider {
    margin: 0 auto;
    text-align: center;
    .slides {
      overflow: hidden;
      width: 1026px;
      height: 730px;
      margin: 0 auto;
      .container {
        transition: margin 700ms;
        width: 10000px;
        text-align: left;
        img {
          display: inline-block;
          width: 1026px;
        }
      }
    }

    .controls {
      & > div {
        display: block;
        width: 36px;
        height: 36px;
        cursor: pointer;
        position: absolute;
        margin-top: 325px;
        margin-left: -17px;
      }
      .left {
        background: url("../assets/img/left_normal.svg") no-repeat;
        //&:hover {
        //  background: url("../assets/img/projects/left_over.png") no-repeat;
        //}
        //&:active {
        //  background: url("../assets/img/projects/left_down.png") no-repeat;
        //}
      }
      .right {
        background: url("../assets/img/right_normal.svg") no-repeat;
        //&:hover {
        //  background: url("../assets/img/projects/right_over.png") no-repeat;
        //}
        //&:active {
        //  background: url("../assets/img/projects/right_down.png") no-repeat;
        //}
        margin-left: 1008px;
      }
    }
    .bullets {
      text-align: center;
      margin-top: -15px;
      .bull {
        display: inline-block;
        width: 9px;
        height: 9px;
        margin: 0 10px;
        cursor: pointer;
        background: url("../assets/img/star_bullit_gray.svg");
        &.active {
          background: url("../assets/img/star_bullit_red.svg");
        }
      }
    }
  }
}