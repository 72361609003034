@media only screen and (max-width: 1069px) {
  @import "resize/1069/navbar";
  @import "resize/1069/base";
  @import "resize/1069/slider";
  @import "resize/1069/company";
  @import "resize/1069/projects";
  @import "resize/1069/workus";
  @import "resize/1069/jobs";
  @import "resize/1069/contacts";
  @import "resize/1069/footer";
  @import "resize/1069/project";
  @import "resize/1069/job";
  @import "resize/1069/modal";
}


@media only screen and (max-width: 767px) {
  @import "resize/767/navbar";
  @import "resize/767/base";
  @import "resize/767/slider";
  @import "resize/767/company";
  @import "resize/767/projects";
  @import "resize/767/workus";
  @import "resize/767/jobs";
  @import "resize/767/contacts";
  @import "resize/767/footer";
  @import "resize/767/project";
  @import "resize/767/job";
}

@media only screen and (max-width: 479px) {
  @import "resize/479/navbar";
  @import "resize/479/base";
  @import "resize/479/slider";
  @import "resize/479/company";
  @import "resize/479/projects";
  @import "resize/479/workus";
  @import "resize/479/jobs";
  @import "resize/479/contacts";
  @import "resize/479/footer";
  @import "resize/479/project";
  @import "resize/479/job";
}