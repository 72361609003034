#Contacts {
  margin-bottom: 53px;
  h2 {
    margin-block-end: 70px;
  }
  a {
    color: #333;
    &:hover {
      .location {
        span {
          border-bottom: 2px solid $pink;
        }
      }
    }
  }
  .inner {
    width: 1026px;
    margin: 0 auto;
    & > div {
      //display: inline-block;
      text-align: center;
      .location {
        text-transform: uppercase;
        font-size: 16px;
        font-weight: bold;
        font-family: Fira Sans OT, Roboto, Arial, Helvetica Neue, Helvetica, sans-serif;
        margin-bottom: 20px;
      }
      .address {
        font-size: 15px;
        font-family: oboto, Arial, Helvetica Neue, Helvetica, sans-serif;
        margin-bottom: 19px;
      }
      //&:last-child {
      //  margin-left: 40px;
      //}
    }
  }
}
