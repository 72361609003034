$ligthgrey: #d7d7d7;
$middlegrey: #a19997;
$midlelightgrey: #999999;
$grey: #333;

$green: #174a45;
$ligthgreen: #00a89e;
$darkgreen: #0f332f;

$pink: #ff5759;

$navbar-height: 76px;