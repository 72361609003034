.slider {
  margin: 0 auto;
  text-align: center;
  .slides {
    overflow: hidden;
    width: 990px;
    height: 486px;
    margin: 0 auto;
    .container {
      transition: margin 700ms;
      width: 10000px;
      text-align: left;
      img {
        display: inline-block;
      }
    }
  }

  .controls {
    & > div {
      display: block;
      width: 36px;
      height: 36px;
      cursor: pointer;
      position: absolute;
      margin-top: 225px;
      margin-left: 38px;
    }
    .left {
      background: url("../assets/img/left_normal.svg") no-repeat;
      //&:hover {
      //  background: url("../assets/img/projects/left_over.png") no-repeat;
      //}
      //&:active {
      //  background: url("../assets/img/projects/left_down.png") no-repeat;
      //}
    }
    .right {
      background: url("../assets/img/right_normal.svg") no-repeat;
      //&:hover {
      //  background: url("../assets/img/projects/right_over.png") no-repeat;
      //}
      //&:active {
      //  background: url("../assets/img/projects/right_down.png") no-repeat;
      //}
      margin-left: 1027px;
    }
  }
  .bullets {
    text-align: center;
    margin-top: 15px;
    .bull {
      display: inline-block;
      width: 9px;
      height: 9px;
      margin: 0 10px;
      cursor: pointer;
      background: url("../assets/img/star_bullit_gray.svg");
      &.active {
        background: url("../assets/img/star_bullit_red.svg");
      }
    }
  }
}