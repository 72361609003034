$modifier_text: 2;

#Jobs {
  margin-bottom: pxtovw(70);

  .inner {
    width: pxtovw(1026);

    .navigation {
      margin-bottom: pxtovw(70);

      .nav {
        width: 20%;
        font-size: pxtovw(25);

        span {
        }

        &:hover,
        &.active {

          span {
          }
        }
      }
    }

    .tabs {
      .tab {
        .description {
          margin-bottom: pxtovw(10);

          .img {
            .img_src {
              height: pxtovw(197);
              width: pxtovw(319);
              margin-bottom: pxtovw(20);
              background-size: pxtovw(319);
            }
          }

          .text {
            padding-left: pxtovw(37);
            font-size: pxtovw(17 * $modifier_text);

            .top {
              font-size: pxtovw(17 * $modifier_text);
              padding-bottom: pxtovw(25);
            }

            p {
              margin-block-start: 0;
            }

            ul {
              margin-left: pxtovw(16);
              margin-top: pxtovw(20);

              li {
                font-size: pxtovw(14 * $modifier_text);
                padding-bottom: pxtovw(20);
              }
            }
          }
        }

        .jobs {
          .job {
            .mailto {
              width: 100%;
              padding-bottom: pxtovw(50);
              padding-top: pxtovw(35);

              .mail-link {
                font-size: pxtovw(16 * $modifier_text);
                width: pxtovw(318);
                height: pxtovw(53);
                margin: 0 auto;
                line-height: pxtovw(53);
                box-shadow: 0 0 pxtovw(8) rgba(255, 255, 255, 0.5);
                border-radius: pxtovw(13);
              }
            }

            .header {
              border-radius: pxtovw(13);
              margin-top: - pxtovw(1);

              & > div {
                font-size: pxtovw(17 * $modifier_text);
                padding: pxtovw(17);
                width: pxtovw(200);
              }

              .title {
                width: pxtovw(450);
                font-size: pxtovw(16 * $modifier_text);
              }

              .toggle {
                width: pxtovw(50);
              }
            }

            .content {
              padding: 0 pxtovw(18);
              font-size: pxtovw(16 * $modifier_text);

              p {
                padding-top: pxtovw(4);
                margin-block-end: pxtovw(10);
                font-size: pxtovw(14 * $modifier_text);

                strong {
                  font-size: pxtovw(16 * $modifier_text);
                  padding-top: pxtovw(20);
                  padding-bottom: pxtovw(20);
                }
              }

              ul {
                margin-left: pxtovw(16 * 3);

                li {
                  font-size: pxtovw(14 * $modifier_text);
                  line-height: 2.3;
                }
              }
            }
          }
        }
      }
    }
  }
}