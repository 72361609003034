@import './src/styles/mixins';

#Rec {
  .inner {
    width: 1070px;
    margin: 0 auto;
    font-size: 14px;
    font-family: "Roboto", Arial, "Helvetica Neue", Helvetica, sans-serif;
  }
  .header {
    margin-top: 75px;
    background-size: cover;
    background-position: 50%;
    background-image: url("/img/photo.jpg");
    background-color: #0b2421;
    height: 653px;
    //width: 100%;
    display: flex;
    align-items: flex-end;
  }
  h1 {
    margin: 0 auto;
    color: #fff;
    font-family: "Fira Sans OT", Helvetica Neue, Helvetica, Arial;
    font-weight: bold;
    font-size: 65px;
    margin-bottom: 50px;
    text-transform: uppercase;
  }
  a {
    color: #03b5c7;
    &:hover {
      text-decoration: underline;
    }
  }
  ul {
    margin-left: 16px;

    li {
      font-size: 14px;
      font-family: "Roboto", Arial, "Helvetica Neue", Helvetica, sans-serif;
      line-height: 1.3;
      list-style-image: url("./../assets/img/star_bullit_red.png");
      margin-bottom: 17px;
    }
  }
  .description {
    margin-top: 90px;
  }
  .bonuses {
    .content {
      display: flex;
      .bonus {
        width: 100%;
        text-align: center;
        .sum {
          color: #00a89e;
          font-family: "Fira Sans OT", Helvetica Neue, Helvetica, Arial;
          font-weight: bold;
          font-size: 65px;
        }
        .desc {
          margin-top: 20px;
        }
      }
    }
  }
  .grid {
    display: flex;
    align-items: center;
    .cell {
      &:first-child {
        padding-right: 36px;
      }
    }
  }
  .mailto {
    display: flex;
    margin-top: 90px;
    margin-bottom: 90px;
    a {
      color: #333;
      display: inline-block;
      text-align: center;
      text-decoration: none;
      font-size: 16px;
      font-weight: bold;
      font-family: "Fira Sans OT", Roboto, Helvetica Neue, Helvetica, Arial;
      width: 318px;
      height: 53px;
      margin: 0 auto;
      line-height: 53px;
      box-shadow: 0 0 8px rgba(255, 255, 255, 0.5);
      border-radius: 13px;
      border: 1px solid #d2d2d2;
      cursor: pointer;
    }
  }
}

$delta: 1.4;

@media only screen and (max-width: 1680px) {
  #Rec {
    .header {
      width: 100%;
      background-size: cover;
      background-position: center;
      height: pxtovw2(653 / $delta);

      img {
        width: 100%
      }
    }
  }
}

//@media only screen and (max-width: 767px)  {
//  #Rec {
//    .header {
//      width: 100%;
//      background-size: pxtovw2(660 * $delta), 100%, pxtovw2(2262 * $delta);
//      background-position: center pxtovw2(54 * $delta), pxtovw2(140 * $delta) pxtovw2(370 * $delta), pxtovw2(-400 * $delta) center;
//      height: pxtovw2(653 * $delta);
//    }
//  }
//}
