#Workus {
  .flex-container {
    display: flex;
    width: 1026px;
    flex-flow: row wrap;
    justify-content: space-between;
    margin: 0 auto;

    .work {
      display: block;
      width: 318px;
      flex-shrink: 3;
      color: #333;
      &:nth-child(1) {
        margin-bottom: 40px;
      }
      .img {
        background: $ligthgreen;
        height: 197px;
        margin-bottom: 20px;
      }
      @for $i from 1 through 6 {
        &:nth-child(#{$i}) {
          .img {
            background: url('../assets/img/workus/job_#{$i}.png') no-repeat;
          }
        }
      }
      .title {
        //padding: 0 18px;
        font-size: 16px;
        //color: $grey;
        font-weight: bold;
        margin-bottom: 12px;
        font-family: "Fira Sans OT", Helvetica Neue, Helvetica, Arial;
      }
      .text {
        //padding: 0 18px;
        font-size: 15px;
        text-align: justify;
        //color: $midlelightgrey;
      }
    }
  }
}