html {
}

body {
  font-family: "Roboto", Arial, "Helvetica Neue", Helvetica, sans-serif;
  color: #333;
}

#container {
  width: 100%;
  overflow: hidden;
  position: relative;
}

h2 {
  font-family: "Fira Sans OT", Roboto, Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-weight: bold;
  text-align: center;
  font-size: 50px;
  text-transform: uppercase;
  margin-block-start: 80px;
  margin-block-end: 80px;
}

.content {
  width: 100%;
  //padding: 0 5px;
  margin: 0 auto;
  text-align: left;
}

.mobile_lang {
  display: none;
}


