#Modal.modal {
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease;
  font-family: Roboto, Arial, Helvetica Neue, Helvetica, sans-serif;
  position: relative;
  z-index: 100;

  ::-webkit-input-placeholder {
    color: #a19996;
    font-size: 15px;
  }

  &.active {
    visibility: visible;
    //transition: all 2s linear;
    opacity: 1;
  }

  .modal-background {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    overflow-y: hidden;
  }

  .modal-content {
    left: 50%;
    margin-left: -335px;
    top: 50%;
    margin-top: -300px;
    width: 670px;
    height: 595px;
    position: fixed;
    background: #fff;
    border-radius: 15px;
    //border: 3px solid #333;
    padding: 35px;
    box-sizing: border-box;

  }

  .modal-title {
    font-size: 27px;
    font-family: "Fira Sans OT", Helvetica Neue, Helvetica, Arial;
    font-weight: bold;
  }

  .modal-prefs {
    color: #5b5b5b;
    font-size: 15px;
    margin-top: 12px;
  }

  .modal-hide {
    position: absolute;
    cursor: pointer;
    right: 20px;
    top: 20px;
    font-size: 20px;
    color: #a19996;
  }

  .modal-field {
    margin-bottom: 15px;

    label {
      font-family: "Fira Sans OT", Roboto, Helvetica Neue, Helvetica, Arial;
      font-size: 17px;
      padding-bottom: 5px;
    }
  }

  input,
  textarea {
    display: block;
    margin-top: 12px;
    border-radius: 10px;
    border: 1px solid #ccc;
    padding: 7px 18px;
    width: 560px;
    height: 20px;
    font-size: 15px;
    color: #333;
  }

  textarea {
    height: 100px;
    resize: none;
  }

  .required-text {
    display: none;
    color: $pink;
  }

  .required {
    .required-text {
      display: inline;
      font-family: "Roboto", Arial, "Helvetica Neue", Helvetica, sans-serif;
      font-weight: normal;
    }

    .modal-field {

    }

    input {
      border: 1px solid $pink;
    }

    ::-webkit-input-placeholder {
      color: $pink;
    }
  }

  .modal-footer {
    width: 100%;
    position: relative;
    text-align: center;
    padding-top: 30px;
  }

  button {
    color: #333;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
    font-family: "Fira Sans OT", Roboto, Helvetica Neue, Helvetica, Arial;
    width: 318px;
    height: 53px;
    margin: 0 auto;
    line-height: 53px;
    box-shadow: 0 0 8px rgba(255, 255, 255, 0.5);
    border-radius: 13px;
    border: 1px solid #d2d2d2;
    cursor: pointer;
    text-align: center;

    &:hover {
      color: #fff;
      background: #00a89e;
    }
  }

  .modal-thx {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }

  .modal-image {
    margin-top: 5px;
  }

  .thx-title {
    font-size: 27px;
    font-family: "Fira Sans OT", Roboto, Helvetica Neue, Helvetica, Arial;
    text-transform: uppercase;
    font-weight: bold;
    margin-top: 50px;
  }

  .thx-text {
    font-size: 18px;
    font-family: Roboto, Helvetica Neue, Helvetica, Arial;
    margin-top: 35px;
  }

  .modal-row {
    margin-top: 36px;

    input {
      width: 280 - 36px;
    }

    & > div {
      display: inline-block;

      &:last-child {
        margin-left: 35px;
      }
    }
  }
}
