@font-face {
  font-family: 'Fira Sans OT';
  src: url('fonts/hinted-FiraSansOTMedium.eot');
  src: local('Fira Sans OT Medium'), local('FiraSansOTMedium'),
  url('fonts/hinted-FiraSansOTMedium.eot?#iefix') format('embedded-opentype'),
  url('fonts/hinted-FiraSansOTMedium.woff2') format('woff2'),
  url('fonts/hinted-FiraSansOTMedium.woff') format('woff'),
  url('fonts/hinted-FiraSansOTMedium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

