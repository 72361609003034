#Slider {
  background: url("../assets/img/main_bg_space.jpg") top center repeat-y #0b2421;
  height: 900px;
  .logo {
    width: 485px;
    height: 317px;
    background-image: url("../assets/img/logo_big.svg");
    background-size: 392px;
    background-repeat: no-repeat;
    //background-position:  top center;
    background-position:  center;
    position: absolute;
    left: 50%;
    margin-left: -242.5px;
    margin-top: 241px;
  }
  .planets {
    position: absolute;
    height: 900px;
    width: 100%;
    background-image: url("../assets/img/main_bg_planet.png");
    background-repeat: no-repeat;
    background-position: center -250px;
    //background-position:  50% 75%;
  }
  .inner {
    width: 100%;
    position: absolute;
    margin: 0 auto;
    text-align: center;
    text-transform: uppercase;
    color: white;
    font-family: "Fira Sans OT", Roboto, Helvetica Neue, Helvetica, Arial;

    .slogan {
      margin-top: 593px;
      font-size: 26px;
      margin-bottom: 70px;
      font-weight: bold;
    }
    .workus {
      position: relative;
      a {
        color: white;
        display: block;
        text-decoration: none;
        font-size: 16px;
        font-weight: bold;

        width: 318px;
        height: 53px;
        margin:  0 auto;
        line-height: 53px;
        //box-shadow: 0 0 8px rgba(255,255,255,0.5);
        //border-radius: 18px;
        //background: url("../assets/img/Button_normal.png");

        border-radius: 13px;
        border: 1px solid #d2d2d2;
        cursor: pointer;
        &:hover,
        &:active {
          background: #fff;
          color: #174a45;
        }
      }
    }
  }
}