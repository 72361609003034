$modifier: 1.7;
#Project {
  .main_image {
    //height: pxtovw(647);
  }

  .not_available {
    flex-direction: column;
  }

  .image {
    display: none !important;
  }

  .inner {

    .content {
      width: pxtovw(1100);
    }
  }

  .social {
    height: pxtovw(181 * $modifier);
    font-size: pxtovw(16 * $modifier);
    padding-top: pxtovw(50 * $modifier);

    &.show {
    }

    .title {
      margin-bottom: pxtovw(20);
    }

    .links {
      ul {
        li {
          width: pxtovw(36 * $modifier);
          height: pxtovw(36 * $modifier);
          margin: 0 pxtovw(8 * $modifier);
          background-size: pxtovw(36 * $modifier) !important;

          a {
            width: pxtovw(35 * $modifier);
            height: pxtovw(35 * $modifier);
          }
        }
      }
    }

  }

  .description {
    padding: 0 pxtovw(38);
    //padding-top: pxtovw(60);
    margin-bottom: - pxtovw(4 * 2);

    & > div {
      display: block;
      &.image {
        text-align: center;
        padding-top: pxtovw(20);
        padding-right: pxtovw(20);
      }
      &.descr {
        width: 100%;
        font-size: pxtovw(40);
      }
    }

    .descr {
      width: pxtovw(680);
      padding-right: pxtovw(20);
      padding-top: pxtovw(58);
      font-size: pxtovw(14);

      .tech {
        ul {
          margin-top: pxtovw(20);

          li {
            width: pxtovw(119 * $modifier);
            height: pxtovw(37 * $modifier);
            margin-right: pxtovw(17 * $modifier);
            background-size: pxtovw(119 * $modifier) !important;
            border: 1px solid #d2d2d2;
            border-radius: pxtovw(7 * $modifier);
            font-size: pxtovw(17 * $modifier);
            line-height: pxtovw(37 * $modifier);
          }
        }
      }
    }
  }

  .features {
    margin: 0 auto;
    margin-top: pxtovw(70);
    width: pxtovw(1024);

    //& > div {
    //  display: block;
    //  width: 100%;
    //  margin-bottom: pxtovw(40);
    //  img {
    //    margin-top: pxtovw(40);
    //    width: 100%;
    //  }
    //}

    ul {
      margin-left: pxtovw(45);

      li {
        font-size: pxtovw(40);
        line-height: 2.3;
      }
    }
  }

  .next_game {
    margin-top: pxtovw(20);
    margin-bottom: pxtovw(70);

    a {
      font-size: pxtovw(16 * $modifier);
      width: pxtovw(318 * $modifier);
      height: pxtovw(53 * $modifier);
      margin: 0 auto;
      line-height: pxtovw(53 * $modifier);
      box-shadow: 0 0 pxtovw(8) rgba(255, 255, 255, 0.5);
      border-radius: pxtovw(13);

      &:hover,
      &:active {
      }
    }
  }

  .video-item {
    width: 100%;
    max-width: pxtovw(1900);
  }
}

.slider {
  margin-top: pxtovw(60);
  .slides {
    width: pxtovw(990);
    height: pxtovw(486);
    margin-left: pxtovw(45);

    .container {
      width: pxtovw(10000);

      img {
        width: pxtovw(990);
      }
    }
  }

  .controls {
    & > div {
      width: pxtovw(36*2);
      height: pxtovw(36*2);
      background-size: pxtovw2(36*2) !important;
      margin-top: pxtovw(180);
      margin-left: pxtovw(20);
    }

    .left {
    }

    .right {
      margin-left: pxtovw(985);
    }
  }

  .bullets {
    margin-top: pxtovw(15*2);

    .bull {
      width: pxtovw2(9*3);
      height: pxtovw2(9*3);
      margin: 0 pxtovw(10);
      background-size: pxtovw2(9*3);
      &.active {
        background-size: pxtovw2(9*3);
      }
    }
  }
}
