#Vacancies {
  margin-bottom: 50px;
  .content {
    margin-top: 132px;
  }
  .vacancies {
    &__flex-container {
      width: 1026px;
      margin: 0 auto;
    }

    &__row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 54px;
      border-top: 1px solid #d2d2d2;
      & > div {
        width: 33.3%;
      }
    }

    &__title {
      font-size: 16px;
      font-weight: bold;
      font-family: "Fira Sans OT", Helvetica Neue, Helvetica, Arial;
      display: flex;
      &-img {
        margin-right: 8px;
      }
      a {
        color: #454545;
        display: flex;
        &:hover {
          top: 3px;
          position: relative;
          &:after {
            content: '';
            width: 20px;
            height: 20px;
            background: url('/img/vac_icons/arrow_right.svg') no-repeat;
            background-size: 12px;
            margin-left: 11px;
            margin-top: 4px;
            position: relative;
          }
        }
      }
    }

    &__occupation {
      color: #a19996;
      text-align: center;
    }

    &__location {
      color: #a19996;
      text-align: right;
    }

    &__navigation {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: 57px;
    }

    &__dep-select {
      select {
        border: 1px solid #d2d2d2;
        border-radius: 12px;
        box-sizing: border-box;
        padding: 20px;
        height: 53px;
        width: 318px;
      }
    }

    &__search {
      margin-left: 36px;
      border-bottom: 1px solid #d2d2d2;
      height: 40px;
      width: 320px;
      &:after {
        display: block;
        position: relative;
        content: '';
        background: url(/img/vac_icons/Icon_lupa.svg) no-repeat center;
        background-size: 20px;
        width: 20px;
        height: 20px;
        margin-top: -26px;
        margin-left: 300px;
      }
      input {
        border: none;
        height: 38px;
        width: 320px;
        outline: none;
        font-size: 16px;
        line-height: normal;
        padding: 0;
      }
    }

    &__department {
      font-size: 30px;
      text-transform: uppercase;
      font-family: "Fira Sans OT", Helvetica Neue, Helvetica, Arial;
      font-weight: bold;
      margin-bottom: 20px;
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    &__text {
      margin-left: 20px;
    }

    &__deps {
      width: 320px;
      height: 53px;
      line-height: 53px;
      text-align: center;
      border: 1px solid #d2d2d2;
      border-radius: 12px;
      box-sizing: border-box;
      font-family: "Fira Sans OT", Helvetica Neue, Helvetica, Arial;
      font-weight: bold;
      text-transform: uppercase;
      z-index: 5;
      position: relative;
      background: #fff;
      cursor: pointer;
      &:after {
        display: block;
        position: absolute;
        content: '';
        background: url(/img/vac_icons/arrow_right.svg) no-repeat center;
        background-size: 12px;
        width: 12px;
        height: 12px;
        margin-top: -33px;
        margin-left: 288px;
        transform: rotate(90deg);
      }
      &_is_clicked {
        &:after {
          transform: rotate(270deg);
        }
      }
    }

    &__options {
      width: 280px;
      text-align: center;
      border: 1px solid #d2d2d2;
      box-sizing: border-box;
      font-family: "Fira Sans OT", Helvetica Neue, Helvetica, Arial;
      font-weight: bold;
      text-transform: uppercase;
      padding: 10px;
      margin-left: 20px;
      margin-top: -2px;
      display: none;
      position: absolute;
      background: #fff;
      &_is_visible {
        display: block;
      }
    }

    &__dep {
      padding-top: 5px;
      padding-bottom: 5px;
      cursor: pointer;
    }

    &__current-dep {
      padding: 8px 0;
      cursor: pointer;
      background: #00a89e;
      color: #fff;
      width: 212px;
      border-radius: 14px;
      text-align: center;
      font-family: "Fira Sans OT", Helvetica Neue, Helvetica, Arial;
      font-size: 16px;
      font-weight: bold;
      text-transform: uppercase;
      margin-left: 18px;
      &:after {
        display: block;
        position: absolute;
        content: '';
        background: url(/img/vac_icons/icon_x.svg) no-repeat center;
        background-size: 13px;
        width: 13px;
        height: 13px;
        margin-top: -16px;
        margin-left: 190px;
      }
    }

    &__current-deps {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 35px;
    }

    &__current-deps-title {
      font-family: "Fira Sans OT", Helvetica Neue, Helvetica, Arial;
      font-size: 16px;
      font-weight: bold;
      text-transform: uppercase;
    }

    &__current-deps-content {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }
  ::-webkit-input-placeholder {
    font-size: 16px;
    font-style: italic;
    line-height: normal;
    text-indent: 1rem;
    white-space: normal;
  }
}
