
#Contacts {
  margin-bottom: pxtovw(53 * 1.5);

  h2 {
    margin-block-end: pxtovw(70);
  }

  a {
    &:hover {
      .location {
        span {
          border-bottom: pxtovw(2) solid $pink;
        }
      }
    }
  }

  .inner {
    width: pxtovw(1026);
    margin: 0 auto;

    & > div {
      width: 100%;
      padding-left: pxtovw(15);
      padding-right: pxtovw(15);
      box-sizing: border-box;
      display: block;
      margin-bottom: pxtovw(50);

      img {
        width: 100%;
      }

      .location {
        font-size: pxtovw(16 * 2.2);
        margin-bottom: 0;
      }

      .address {
        font-size: pxtovw(15 * 2.5);
        margin-bottom: pxtovw(19);
      }

      &:last-child {
        margin-left: 0;
      }
    }
  }
}